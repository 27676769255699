
import 'react-app-polyfill/stable';
import "core-js";
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers'
import { icons } from './assets/icons'

React.icons = icons;

const composeEnhancers = composeWithDevTools({});
const store = createStore(
  rootReducer ,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>, 
  document.getElementById('root')
);



setTimeout(() => { 

  try {
    let system=JSON.parse(localStorage.getItem('system'));
    ReactDOM.render(
      system.platform_name, 
      document.getElementById('title')
    );
  }
  catch {
    ReactDOM.render(
      "", 
      document.getElementById('title')
    );
  }

}, 3000);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
