export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const USER_ACTIVITY = "USER_ACTIVITY";
export const SETTINGS = "SETTINGS";
export const SYSTEM_INFO = "SYSTEM_INFO";
export const LOGIN_DETAILS = "LOGIN_DETAILS";
export const SOUND = "SOUND";
export const AUTO_REFRESH = "AUTO_REFRESH";
export const TENANT_INFO = "TENANT_INFO";
export const TENANT_NAME = "TENANT_NAME";
export const API_BASE_URL = "API_BASE_URL";
export const HISTORY_COUNTER = "HISTORY_COUNTER";
export const USER_DATA = "USER_DATA";
export const ACTIVE_EVENTS = "ACTIVE_EVENTS";
export const APPLICATION_DATA = 'APPLICATION_DATA';
export const ALL_CAMPAIGNS_DATA = "ALL_CAMPAIGNS_DATA";
export const CURRENT_CAMPAIN = "CURRENT_CAMPAIN";
export const CAMPAIGNS_FILTERS = 'CAMPAIGNS_FILTERS';



