import { 
    ALL_CAMPAIGNS_DATA,
    CURRENT_CAMPAIN,
    CAMPAIGNS_FILTERS
} from "../actions/actionTypes";

const initialState = {
    allCampaignsReduxData: [],
    campaignFiltersReduxData:{},
    currentCampaignReduxData: {}
}

const campaignBuilderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_CAMPAIGNS_DATA: {
            return {...state, allCampaignsReduxData: action.payload};
        }
        case CURRENT_CAMPAIN: {
            return {...state, currentCampaignReduxData: action.payload};
        }
        case CAMPAIGNS_FILTERS: {
            return {...state, campaignFiltersReduxData: action.payload};
        }
        default:
            return state;
    }
};

export default campaignBuilderReducer;
