import { SHOW_SIDEBAR } from "../actions/actionTypes";

const initialState = {
    show: 'responsive'
}

const showSideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SIDEBAR: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default showSideBarReducer;
