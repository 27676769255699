import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  ProtectedRoute,
  UnauthenticatedRoute,
  isAuthenticated,
} from "routes/protected-route";
import { blackMamba } from "./styles/theme";
import { GlobalStyles } from "./styles/global";
//import { getThemePaletteByThemeName } from "styles/player_theme_palete";
import "./scss/style.scss";

const loading = (
  <div className=" text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./containers/layouts/layout-manager")
);

// Pages
const Auth = React.lazy(() => import("pages/unauthorized/unauth-main"));

const RegistrationSignin = React.lazy(() =>
  import("./views/pages/registration/RegistrationSignin")
);

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: null,
      loading: true,
      player: false,
    };
  }

  componentDidMount() {
    //let settings = JSON.parse(localStorage.getItem("settings"));

    // if (settings) {
    //if (settings.theme === "player") {
    //this.setState({ theme: getThemePaletteByThemeName("eternal_blue") });
    // this.setState({ theme: getThemePaletteByThemeName("cywaria_green") });
    //} else {
    // this.setState({ theme: getThemePaletteByThemeName("blackMamba") });
    //}
    //this.setState({ theme: settings.theme });
    // } else {
    // SET DEFAULT THEME
    this.setState({ theme: blackMamba });
    //}
  }

  render() {
    return (
      <>
        {this.state.theme ? (
          <ThemeProvider theme={this.state.theme}>
            <GlobalStyles />
            <HashRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <UnauthenticatedRoute
                    exact
                    path="/login"
                    name="Login Page"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    exact
                    path="/auth"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/auth/:mode"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/auth/:mode/:username"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/register/event/:event_id"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/confirm/:username"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/forgot-password"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />

                  <UnauthenticatedRoute
                    exact
                    path="/registration/form"
                    name="Registration Form"
                    component={RegistrationSignin}
                    render={(props) => <RegistrationSignin {...props} />}
                  />

                  <Route
                    exact
                    path="/404"
                    name="Page 404"
                    component={Page404}
                    render={(props) => <Page404 {...props} />}
                  />
                  <Route
                    exact
                    path="/500"
                    name="Page 500"
                    component={Page500}
                    render={(props) => <Page500 {...props} />}
                  />
                  <ProtectedRoute
                    path="/"
                    component={TheLayout}
                    isAuthenticated={isAuthenticated()}
                    render={(props) => <TheLayout {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </HashRouter>
          </ThemeProvider>
        ) : null}
      </>
    );
  }
}

export default App;
