import { hexToRgba } from "@coreui/utils/src"

// ICONS
import calendar_white from '../assets/icons/calendar_white.svg';
import calendar_black from '../assets/icons/calendar_black.svg';
import clock_white from '../assets/icons/clock_white.svg';
import clock_black from '../assets/icons/clock_black.svg';
import network_white from '../assets/icons/network_white.svg';
import network_black from '../assets/icons/network_black.svg';

import { ReactComponent as ConnectedHirarchy } from '../views/network-map/icons/connectedHirarch.svg';

// COLORS
export const cympire_colors  = {
    green: '73, 189, 149', // #49bd95
    green_1: '49,153,61', // #31993d
    green_2: '23,191,131', // #17bf83
    old_green: '24,121,99', // #187963 (v2)
    glow_green: '0,255,176', // #00ffb0 (v2)
    purple: '48,35,114', 
    old_purple: '116,113,251', // #7471fb (v2)
    yellow: '244,245,144',
    orange: '255,116,0',
    red: '255, 106, 103', // #FF6A67
    blue_1: '100, 205, 255', // #64cdff
    blue_2: '42, 131, 254',//2a84ff
    blue: '50, 137, 210', // #3289D2
    grey:  '153, 153, 153', //#999999
    grey_2: '134, 134, 134', //#868686
    gray_3: '38, 38, 38',//#262626
    gray_4: '42, 42, 42',//#2a2a2a
    dark_grey:  '32, 32, 32', //#222222
    medium_grey: '45, 46, 48', //#2d2e30
    light_grey: '216, 219, 224', //#D8DBE0
    deep_gray: '19, 19, 19',  //#131313
    mat_black: '45, 46, 48', // #2d2e30
    black: '0, 0, 0',
    white: '255, 255, 255',
    navy: '21, 36, 51', // #152433
    cyberpink: '255,41,109', // #ff286d
    cybergreen: '5,215,230', // #05d9e8
}





// theme.js


export const whiteNoise = {

    // ICONS
    calendar: calendar_black,
    clock: clock_black,

    body: `rgb(${cympire_colors.white})`,
    text: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    text_hover: `rgba(${cympire_colors.dark_grey}, 0.75)`,
    toggleBorder: `rgb(${cympire_colors.dark_grey})`,
    //gradient: 'linear-gradient(#39598A, #79D7ED)',
    
    // GLOBAL
    background: `rgb(${cympire_colors.white})`,
    base_background: `rgb(${cympire_colors.white})`,
    link: `rgb(${cympire_colors.grey})`,
    link_hover: `rgb(${cympire_colors.white})`,

    // HEADER
    header: `rgb(${cympire_colors.mat_black})`,
    header_text: `rgb(${cympire_colors.white})`,
    header_link: `rgba(${cympire_colors.white}, 0.5)`,
    header_link_hover: `rgba(${cympire_colors.white}, 1.0)`,
    header_border: `rgb(${cympire_colors.mat_black})`,
    header_dropdown: `rgb(${cympire_colors.medium_grey})`,
    header_dropdown_border: `rgba(${cympire_colors.medium_grey}, 0.7)`,
    header_dropdown_header: `rgb(${cympire_colors.dark_grey})`,
    header_dropdown_item: `rgba(${cympire_colors.medium_grey}, 0.1)`,
    header_dropdown_item_text: `rgba(${cympire_colors.white}, 0.75)`,
    header_dropdown_divider: `rgb(${cympire_colors.light_grey})`,

    // SUB-HEADER
    subheader: `rgb(${cympire_colors.white})`,
    subheader_breadcrumb_item: `rgb(${cympire_colors.white})`,
    subheader_link: `rgba(${cympire_colors.white}, 0.5)`,
    subheader_link_hover: `rgba(${cympire_colors.white}, 1.0)`,
    subheader_style: 'background: linear-gradient(to right, rgba(0,0,0, 1.0) 1%, rgba(0,0,0, 0.3) 5%, rgba(255,255,255, 0.8) 50%, rgba(0,0,0, 0.3) 95%, rgba(0,0,0, 1.0) 100% ) 0 100% ;',
    subheader_border_style: 'border-image: linear-gradient(to right, rgba(255,255,255, 0.5) 1%, rgba(255,255,255, 0.3) 5%, rgba(255,255,255, 0.1) 50%, rgba(255,255,255, 0.3) 95%, rgba(255,255,255, 0.5) 100% ) 1 ;',


    // FOOTER
    footer: `rgb(${cympire_colors.mat_black})`,
    footer_border: `rgb(${cympire_colors.mat_black})`,


    // SIDEBAR
    sidebar_header: '#323232',//`rgb(${cympire_colors.dark_grey})`,
    sidebar_header_text: '#252628',
    sidebar_body: '#2a2b2d',
    sidebar_body_hover: 'rgba(0, 0, 0, 0.88)',
    sidebar_body_text: '#777777',
    sidebar_body_nav_title: `rgb(${cympire_colors.grey_2})`,

    // SLIDER
    slider_range: `rgb(${cympire_colors.green})`,

    // FORMS
    form: `rgb(${cympire_colors.white})`,
    form_title: `rgb(${cympire_colors.white})`,
    form_subtitle: `rgb(${cympire_colors.grey})`,
    form_text: `rgb(${cympire_colors.black})`,
    form_border: `rgba(${cympire_colors.light_grey})`,
    form_field_info: `rgb(${cympire_colors.grey})`,
    form_field_warning: `rgb(${cympire_colors.red})`,
    form_hline: `rgb(${cympire_colors.light_grey})`,

    // SELECT
    select: `rgb(${cympire_colors.white})`,
    select_text: `rgb(${cympire_colors.black})`,
    select_border: `rgb(${cympire_colors.light_grey})`,
    select_item_hover: `rgba(${cympire_colors.black}, 0.1)`,
    select_item_selected: `rgba(${cympire_colors.black}, 0.5)`,
    select_item_badge: `rgb(${cympire_colors.dark_grey})`,
    select_item_badge_text: `rgb(${cympire_colors.white})`,

    // INPUTS
    input_border: `rgb(${cympire_colors.black}, 0.2)`,
    input: `rgb(${cympire_colors.white})`,

    // RADIO
    radio: `rgb(${cympire_colors.green})`,

    // SWITCH SLIDER
    switch_slider: `rgb(${cympire_colors.white})`,
    switch_slider_border: `rgb(${cympire_colors.light_grey})`,

    // SWITCHES
    switch: `rgb(${cympire_colors.white})`,
    success_switch: `rgb(${cympire_colors.green})`,
    danger_switch: `rgb(${cympire_colors.red})`,
    info_switch: `rgb(${cympire_colors.blue})`,
    warning_switch: `rgb(${cympire_colors.yellow})`,

    // TOOLTIP
    tooltip_text: `rgb(${cympire_colors.white})`,

    // BADGES 
    badge: `rgba(${cympire_colors.green}, 0.8)`,
    badge_text: `rgb(${cympire_colors.white})`,

    badge_red: `rgb(${cympire_colors.red})`,
    badge_red_text: `rgb(${cympire_colors.white})`,

    badge_success: `rgb(${cympire_colors.green})`,
    badge_success_text: `rgb(${cympire_colors.white})`,

    badge_danger: `rgb(${cympire_colors.red})`,
    badge_danger_text: `rgb(${cympire_colors.white})`,

    badge_info: `rgb(${cympire_colors.blue})`,
    badge_info_text: `rgb(${cympire_colors.white})`,


    // TABS
    red_tabs: `rgba(${cympire_colors.light_grey}, 0.5)`,
    red_tabs_text: `rgba(${cympire_colors.black}, 0.3)`,
    red_tabs_active: `rgb(${cympire_colors.white})`,
    red_tabs_active_text: `rgb(${cympire_colors.black})`,
    red_tabs_border: `rgb(${cympire_colors.red})`,
    red_tabs_border_hover: `rgba(${cympire_colors.red}, 0.5)`,
    red_tabs_border_bottom: `rgb(${cympire_colors.white})`,

    blue_tabs: `rgba(${cympire_colors.light_grey}, 0.5)`,
    blue_tabs_text: `rgba(${cympire_colors.black}, 0.3)`,
    blue_tabs_active: `rgb(${cympire_colors.white})`,
    blue_tabs_active_text: `rgb(${cympire_colors.black})`,
    blue_tabs_border: `rgb(${cympire_colors.blue})`,
    blue_tabs_border_hover: `rgba(${cympire_colors.blue}, 0.5)`,
    blue_tabs_border_bottom: `rgb(${cympire_colors.white})`,

    default_tabs: `rgba(${cympire_colors.light_grey}, 0.5)`,
    default_tabs_text: `rgba(${cympire_colors.black}, 0.3)`,
    default_tabs_active: `rgb(${cympire_colors.white})`,
    default_tabs_active_text: `rgb(${cympire_colors.black})`,
    default_tabs_border: `rgb(${cympire_colors.light_grey})`,
    default_tabs_border_hover: `rgba(${cympire_colors.light_grey}, 0.5)`,
    default_tabs_border_bottom: `rgb(${cympire_colors.white})`,

    

    // TABLES
    table_header: `rgba(${cympire_colors.navy}, 1.0)`,
    table_header_hover: `rgba(${cympire_colors.navy}, 0.9)`,
    table_header_text: `rgba(${cympire_colors.white}, 0.8)`,
    table_row_hover: 'rgba(247, 247, 247, 1.0)',
    table_text: `rgba(${cympire_colors.black}, 0.75)`,
    paginator: `rgba(${cympire_colors.navy}, 1.0)`,
    paginator_text: `rgba(${cympire_colors.white}, 1.0)`,
    table_btn_outline: `rgba(${cympire_colors.navy}, 1.0)`,
    table_btn_outline_text: `rgba(${cympire_colors.white}, 1.0)`,
    table_btn_outline_border: `rgba(${cympire_colors.navy}, 1.0)`,

    table_stop_button: `rgba(${cympire_colors.red}, 1.0)`,
    table_stop_button_text: `rgba(${cympire_colors.white}, 1.0)`,
    table_stop_button_text_hover: `rgba(${cympire_colors.white}, 1.0)`,
    table_stop_button_border: `rgba(${cympire_colors.red}, 1.0)`,
    table_stop_button_border_hover: `rgba(${cympire_colors.red}, 1.0)`,

    table_continue_button: `rgba(${cympire_colors.green}, 1.0)`,
    table_continue_button_text: `rgba(${cympire_colors.white}, 1.0)`,
    table_continue_button_text_hover: `rgba(${cympire_colors.white}, 1.0)`,
    table_continue_button_border: `rgba(${cympire_colors.green}, 1.0)`,
    table_continue_button_border_hover: `rgba(${cympire_colors.green}, 1.0)`,

    table_terminate_button: `rgba(${cympire_colors.red}, 1.0)`,
    table_terminate_button_text: `rgba(${cympire_colors.white}, 1.0)`,
    table_terminate_button_text_hover: `rgba(${cympire_colors.white}, 1.0)`,
    table_terminate_button_border: `rgba(${cympire_colors.red}, 1.0)`,
    table_terminate_button_border_hover: `rgba(${cympire_colors.red}, 1.0)`,


    
    // MODALS
    modal_background: `rgba(${cympire_colors.white}, 1.0)`,
    modal_header_border: `rgba(${cympire_colors.light_grey}, 1.0)`,
    modal_footer_border: `rgba(${cympire_colors.light_grey}, 1.0)`,
    modal_success_button: `rgba(${cympire_colors.green}, 1.0)`,
    modal_success_button_text: `rgba(${cympire_colors.black}, 1.0)`,
    modal_delete_button: `rgba(${cympire_colors.red}, 1.0)`,
    modal_delete_button_text: `rgba(${cympire_colors.black}, 1.0)`,
    modal_cancel_button: `rgba(${cympire_colors.light_grey}, 1.0)`,
    modal_cancel_button_text: `rgba(${cympire_colors.black}, 1.0)`,

    // BUTTONS
    button: `rgba(${cympire_colors.navy}, 1.0)`,
    button_hover: `rgba(${cympire_colors.navy}, 1.0)`,
    button_border: `rgba(${cympire_colors.navy}, 0.8)`,
    button_border_hover: `rgba(${cympire_colors.navy}, 1.0)`,
    button_text: `rgba(${cympire_colors.white}, 0.8)`,
    button_text_hover: `rgba(${cympire_colors.white}, 1.0)`,
    button_shadow: 'rgba(21, 36, 51, 0.25);',

    edit_button: `rgba(${cympire_colors.black}, 1.0)`,
    edit_button_hover: `rgba(${cympire_colors.black}, 0.6)`,

    add_button: `rgb(${cympire_colors.black})`,
    add_button_text: `rgb(${cympire_colors.white})`,
    add_button_border: `rgb(${cympire_colors.white})`,

    btn_light: `rgb(${cympire_colors.black})`,
    btn_light_text: `rgba(${cympire_colors.light_grey}, 0.8)`,
    btn_light_border: `rgba(${cympire_colors.light_grey}, 0.8)`,
    btn_light_hover: `rgba(${cympire_colors.light_grey}, 1.0)`,

    btn_danger: `rgb(${cympire_colors.red})`,
    btn_danger_text: `rgba(${cympire_colors.white}, 1.0)`,
    btn_danger_border: `rgba(${cympire_colors.red}, 1.0)`,
    //btn_danger_hover: `rgba(${cympire_colors.red}, 1.0)`,

    btn_info: '#49bd95',
    btn_info_text: '#FFFFFF',
    btn_info_border: '#49bd95',
    
    btn_update: `rgba(${cympire_colors.green}, 1.0)`,
    btn_update_text: `rgba(${cympire_colors.white}, 1.0)`,
    btn_update_border: `rgba(${cympire_colors.green}, 1.0)`,
    //btn_update_hover: `rgba(${cympire_colors.green}, 1.0)`,

    btn_create: `rgba(${cympire_colors.green}, 1.0)`,
    btn_create_text: `rgba(${cympire_colors.white}, 1.0)`,
    btn_create_border: `rgba(${cympire_colors.green}, 1.0)`,
    btn_create_hover: `rgba(${cympire_colors.green}, 1.0)`,


    // ALERTS
    alert_danger: `rgb(${cympire_colors.white})`,
    alert_danger_text: `rgba(${cympire_colors.red}, 1.0)`,
    alert_danger_border: `rgb(${cympire_colors.red})`,

    alert_info: `rgb(${cympire_colors.white})`,
    alert_info_text: `rgba(${cympire_colors.grey}, 1.0)`,
    alert_info_border: `rgb(${cympire_colors.grey})`,

    alert_success: `rgb(${cympire_colors.white})`,
    alert_success_text: `rgba(${cympire_colors.green}, 1.0)`,
    alert_success_border: `rgb(${cympire_colors.green})`,

    

    
    /* MODULES */

    // LICENSE
    license_grid: `rgb(${cympire_colors.white})`,
    license_grid_text: `rgba(${cympire_colors.black}, 0.75)`,
    license_hline: `rgba(${cympire_colors.light_grey}, 0.75)`,
    license_info_text: `rgba(${cympire_colors.white}, 0.8)`,
    license_info_vline: `rgba(${cympire_colors.white}, 0.1)`,
    license_info_vline_text: `rgba(${cympire_colors.white}, 0.5)`,
    license_grid_vline_border: `rgba(${cympire_colors.white}, 0.1)`,
    license_campaign_grid_vline_style: 'border-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0.1), rgba(0,0,0, 0.2), rgba(255,255,255, 0.1), rgba(255,255,255, 0) ) 1 100%;',


    // CAMPAIGNS
    campaigns_grid: `rgb(${cympire_colors.white})`,
    campaigns_grid_text: `rgb(${cympire_colors.black})`,
    campaigns_hline: `rgba(${cympire_colors.black}, 0.1)`,
    campaigns_info_text: `rgba(${cympire_colors.black}, 0.5)`,
    campaigns_info_vline: `rgba(${cympire_colors.black}, 0.1)`,
    campaigns_info_vline_text: `rgba(${cympire_colors.black}, 0.5)`,
    campaigns_grid_vline_border: `rgba(${cympire_colors.black}, 0.1)`,
    campaigns_section_title_text: `rgba(${cympire_colors.black}, 0.5)`,
    campaigns_topics_text: `rgba(${cympire_colors.black}, 0.8)`,
    campaigns_industries_text: `rgba(${cympire_colors.black}, 0.8)`,
    campaigns_filter_container_border: `rgba(${cympire_colors.black}, 0.3)`,
    campaigns_filter_reset_button: `rgba(${cympire_colors.black}, 0.8)`,
    campaigns_filter_reset_button_hover: `rgba(${cympire_colors.black}, 0.5)`,
    campaigns_select_campaign_button: `rgba(${cympire_colors.black}, 1.0)`,
    campaigns_select_campaign_button_text: `rgb(${cympire_colors.white})`,
    campaigns_select_campaign_button_border: `rgb(${cympire_colors.white})`,
    campaigns_button: `rgb(${cympire_colors.navy})`,
    campaigns_button_text: `rgba(${cympire_colors.white}, 1.0)`,
    campaigns_button_text_hover: `rgba(${cympire_colors.white}, 1.0)`,
    campaigns_button_border: `rgb(${cympire_colors.navy})`,
    campaigns_button_border_hover: `rgb(${cympire_colors.navy})`,
    campaigns_campaign_grid_vline_style: 'border-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0.1), rgba(0,0,0, 0.2), rgba(255,255,255, 0.1), rgba(255,255,255, 0) ) 1 100%;',
    campaigns_video_brief_video_shadow: '0,0,0',

    // CAMPAIGN
    campaign_hline: `rgba(${cympire_colors.black}, 0.1)`,
    campaign_info_vline: `rgba(${cympire_colors.black}, 0.1)`,
    campaign_mission_hline: `rgba(${cympire_colors.black}, 0.2)`,
    campaign_mission_border: `rgba(${cympire_colors.black}, 0.2)`,
    campaign_section_text: `rgba(${cympire_colors.black}, 0.5)`,
    campaign_topics_text: `rgba(${cympire_colors.black}, 0.8)`,
    campaign_industries_text: `rgba(${cympire_colors.black}, 0.8)`,
    campaign_title: `rgba(${cympire_colors.black}, 0.8)`,
    campaign_subtitle: `rgba(${cympire_colors.black}, 0.7)`,
    campaign_target_image_shadow: '0, 0, 0',

    
    // TEAMS
    teams_border: `rgba(${cympire_colors.black}, 0.2)`,
    teams_title: `rgba(${cympire_colors.black}, 1.0)`,
    teams_hline: `rgba(${cympire_colors.black}, 0.2)`,
    teams_container: `rgba(${cympire_colors.white}, 1.0)`,
    teams_container_border: `rgba(${cympire_colors.black}, 0.1)`,
    teams_team_title: `rgba(${cympire_colors.black}, 1.0)`,
    teams_team_subtitle: `rgba(${cympire_colors.grey}, 1.0)`,


    //  PACKAGES
    package_grid: `rgb(${cympire_colors.white})`,
    package_text: `rgb(${cympire_colors.black})`,
    package_hline: `rgba(${cympire_colors.black}, 0.2)`,
    package_info_text: `rgba(${cympire_colors.black}, 0.7)`,
    package_section_title: `rgba(${cympire_colors.black}, 0.5)`,
    package_topics: `rgba(${cympire_colors.black}, 0.8)`,
    package_industries: `rgba(${cympire_colors.black}, 0.8)`,
    package_grid_vline_style: 'border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(0,0,0, 0.2), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0) ) 1 100%;',



    // TEAMS
    teams_avatar_image_border: `rgb(${cympire_colors.grey})`,
    teams_player_text: `rgba(${cympire_colors.grey}, 0.75)`,


    // USERS
    users_avatar_image_border: `rgb(${cympire_colors.grey})`,
    

    // NETWROK MAP (CAMPAIGN BUILDER)
    network_map_text: `rgba(${cympire_colors.black}, 1.0)`,
    network_map_background: `rgba(${cympire_colors.white}, 1.0)`,
    network_map_border: `rgba(${cympire_colors.light_grey}, 1.0)`,
    network_map_input: `rgba(${cympire_colors.white}, 1.0)`,
    network_map_segment_border: `rgba(${cympire_colors.light_grey}, 0.8)`,
    network_map_object: `rgba(${cympire_colors.black}, 0.05)`,
    network_map_vline_style: 'border-image: linear-gradient( to bottom, rgba(255,255,255, 0.1) , rgba(0,0,0, 0.15), rgba(0,0,0, 0.2), rgba(0,0,0, 0.15), rgba(255,255,255, 0.1) ) 1 100%;',
    

    // EVENT DASHBOARD
    event_dashboard_completed_text: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    event_dashboard_card_border: `rgba(${cympire_colors.black}, 0.25)`,
    event_dashboard_divider: `rgba(${cympire_colors.light_grey}, 1.0)`,
    event_dashboard_card_divider: `rgba(${cympire_colors.light_grey}, 1.0)`,
    event_dashboard_white_border: `rgba(${cympire_colors.white}, 1.0)`,
    event_dashboard_grey_border: `rgba(${cympire_colors.grey}, 1.0)`,
    event_dashboard_red_border: `rgba(${cympire_colors.red}, 1.0)`,
    event_dashboard_blue_border: `rgba(${cympire_colors.blue}, 1.0)`,
    event_dashboard_green_border: `rgba(${cympire_colors.green}, 1.0)`,


    // ATTACK SCENARIO
    attack_scenario_timeline: "transparent",
    attack_scenario_timeline_border: `rgba(${cympire_colors.medium_grey}, 0.5)`,
    attack_scenario_background: `rgba(${cympire_colors.white}, 1.0)`,
    attack_scenario_border: `rgba(${cympire_colors.light_grey}, 1.0)`,
    attack_scenario_target_phases_text: `rgba(${cympire_colors.black}, 1.0)`,
    attack_scenario_target_phases_border: `rgba(${cympire_colors.light_grey}, 1.0)`,
    attack_scenario_side_border: `rgba(${cympire_colors.red}, 1.0)`,
    


}
  





































export const blackMamba = {
    
    // ICONS
    calendar: calendar_white,
    clock: clock_white,
    network_icon: network_white,

    body: `rgb(${cympire_colors.black})`,
    text: `rgba(${cympire_colors.white}, 0.75)`,
    text_hover: `rgba(${cympire_colors.white}, 0.5)`,
    toggleBorder: `rgb(${cympire_colors.white})`,
    //gradient: 'linear-gradient(#39598A, #79D7ED)',
    
    // GLOBAL
    //background: `rgb(${cympire_colors.deep_gray})`,black
    background: `rgb(${cympire_colors.black})`,
    base_background: `rgb(${cympire_colors.black})`,
    link: `rgb(${cympire_colors.grey})`,
    link_hover: `rgb(${cympire_colors.white})`,

    // HEADER
    //header: `rgb(${cympire_colors.deep_gray})`,
    header: `rgb(${cympire_colors.black})`,
    header_text: `rgb(${cympire_colors.white})`,
    header_link: `rgba(${cympire_colors.white}, 0.5)`,
    header_link_hover: `rgba(${cympire_colors.white}, 1.0)`,
    header_border: `rgb(${cympire_colors.gray_3})`,
    header_dropdown: `rgb(${cympire_colors.medium_grey})`,
    header_dropdown_border: `rgba(${cympire_colors.medium_grey}, 0.7)`,
    header_dropdown_header: `rgb(${cympire_colors.dark_grey})`,
    header_dropdown_item: `rgba(${cympire_colors.medium_grey}, 0.1)`,
    header_dropdown_item_text: `rgba(${cympire_colors.white}, 0.75)`,
    header_dropdown_divider: `rgb(${cympire_colors.light_grey})`,

    // SUB-HEADER
    subheader: '#343537',
    subheader_breadcrumb_item: `rgb(${cympire_colors.white})`,
    subheader_link: `rgba(${cympire_colors.white}, 0.5)`,
    subheader_link_hover: `rgba(${cympire_colors.white}, 1.0)`,
    subheader_style: 'background: linear-gradient(to right, rgba(0,0,0, 1.0) 1%, rgba(0,0,0, 0.3) 5%, rgba(255,255,255, 0.5) 50%, rgba(0,0,0, 0.3) 95%, rgba(0,0,0, 1.0) 100% ) 0 100% ;',
    subheader_border_style: 'border-image: linear-gradient(to right, rgba(255,255,255, 0.5) 1%, rgba(255,255,255, 0.3) 5%, rgba(255,255,255, 0.1) 50%, rgba(255,255,255, 0.3) 95%, rgba(255,255,255, 0.5) 100% ) 1 ;',
    
    // FOOTER
    footer: `rgb(${cympire_colors.black})`,
    footer_border: `rgb(${cympire_colors.light_grey})`,

    // SIDEBAR
    sidebar_header: '#323232',//`rgb(${cympire_colors.dark_grey})`,
    sidebar_header_text: '#252628',
    sidebar_body: '#363636', //'#2a2b2d',
    sidebar_body_hover: 'rgba(0, 0, 0, 0.88)',
    sidebar_body_nav_title: `rgb(${cympire_colors.grey_2})`,
    sidebar_body_text: '#777777',
    
    // SLIDER
    slider_range: `rgb(${cympire_colors.green})`,
    
    // FORMS
    form: `rgb(${cympire_colors.black})`,
    form_title: `rgb(${cympire_colors.black})`,
    form_subtitle: `rgba(${cympire_colors.grey}, 1.0)`,
    form_text: `rgba(${cympire_colors.white}, 0.75)`,
    form_border: `rgb(${cympire_colors.light_grey})`,
    form_field_info: `rgb(${cympire_colors.light_grey})`,
    form_field_warning: `rgb(${cympire_colors.red})`,
    form_hline: `rgb(${cympire_colors.light_grey})`,

    // SELECT
    select: `rgb(${cympire_colors.black})`,
    select_text: `rgb(${cympire_colors.white})`,
    select_text_disabled: `rgba(${cympire_colors.white},  0.3)`,
    select_border: `rgba(${cympire_colors.white}, 0.5)`,
    select_item_hover: `rgba(${cympire_colors.white}, 0.1)`,
    select_item_selected: `rgba(${cympire_colors.white}, 0.5)`,
    select_item_badge: `rgb(${cympire_colors.dark_grey})`,
    select_item_badge_text: `rgb(${cympire_colors.white})`,

    // INPUTS
    input_border: `rgba(${cympire_colors.white}, 0.5)`,
    input: `rgb(${cympire_colors.black})`,

    // RADIO
    radio: `rgb(${cympire_colors.green})`,

    // SWITCH SLIDER
    switch_slider: `rgb(${cympire_colors.white})`,
    switch_slider_border: `rgb(${cympire_colors.light_grey})`,

    // SWITCHES
    switch: `rgb(${cympire_colors.light_grey})`,
    success_switch: `rgb(${cympire_colors.green})`,
    danger_switch: `rgb(${cympire_colors.red})`,
    info_switch: `rgb(${cympire_colors.blue})`,

    // TOOLTIP
    tooltip_text: `rgb(${cympire_colors.white})`,

    // BADGES 
    badge: `rgba(${cympire_colors.green}, 0.8)`,
    badge_text: `rgb(${cympire_colors.black})`,

    badge_red: `rgb(${cympire_colors.red})`,
    badge_red_text: `rgb(${cympire_colors.black})`,

    badge_success: `rgb(${cympire_colors.green})`,
    badge_success_text: `rgb(${cympire_colors.black})`,

    badge_danger: `rgb(${cympire_colors.red})`,
    badge_danger_text: `rgb(${cympire_colors.black})`,


    badge_log: `rgb(${cympire_colors.grey})`,
    badge_log_text: `rgb(${cympire_colors.black})`,

    badge_info: `rgb(${cympire_colors.blue})`,
    badge_info_text: `rgb(${cympire_colors.black})`,

    badge_low: `rgb(${cympire_colors.yellow})`,
    badge_low_text: `rgb(${cympire_colors.black})`,

    badge_medium: `rgb(${cympire_colors.orange})`,
    badge_medium_text: `rgb(${cympire_colors.black})`,

    badge_high: `rgb(${cympire_colors.red})`,
    badge_high_text: `rgb(${cympire_colors.black})`,

    // TABS
    red_tabs: '#111111',
    red_tabs_text: '#444444',
    red_tabs_active: `rgb(${cympire_colors.dark_grey})`,
    red_tabs_active_text: `rgb(${cympire_colors.white})`,
    red_tabs_border: `rgb(${cympire_colors.red})`,
    red_tabs_border_hover: `rgba(${cympire_colors.red}, 0.5)`,
    red_tabs_border_bottom: `rgb(${cympire_colors.dark_grey})`,

    blue_tabs: '#111111',
    blue_tabs_text: '#444444',
    blue_tabs_active: `rgb(${cympire_colors.dark_grey})`,
    blue_tabs_active_text: `rgb(${cympire_colors.white})`,
    blue_tabs_border: `rgb(${cympire_colors.blue})`,
    blue_tabs_border_hover: `rgba(${cympire_colors.blue}, 0.5)`,
    blue_tabs_border_bottom: `rgb(${cympire_colors.dark_grey})`,

    default_tabs: '#111111',
    default_tabs_text: '#444444',
    default_tabs_active: `rgb(${cympire_colors.black})`,
    default_tabs_active_text: `rgba(${cympire_colors.white}, 0.75)`,
    default_tabs_border: `rgb(${cympire_colors.light_grey})`,
    default_tabs_border_hover: `rgba(${cympire_colors.light_grey}, 0.5)`,
    default_tabs_border_bottom: `rgb(${cympire_colors.black})`,


    // TABLES
    table_header: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    table_header_hover: `rgba(${cympire_colors.dark_grey}, 0.8)`,
    table_header_text: `rgba(${cympire_colors.white}, 0.75)`,
    table_row: `rgba(${cympire_colors.white}, 0.1)`,
    table_row_hover: `rgba(${cympire_colors.white}, 0.05)`,
    table_text: `rgba(${cympire_colors.white}, 0.75)`,
    paginator: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    paginator_text: `rgba(${cympire_colors.white}, 0.75)`,
    table_btn_outline: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    table_btn_outline_text: `rgba(${cympire_colors.white}, 1.0)`,
    table_btn_outline_border: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    
    table_stop_button: `rgba(${cympire_colors.black}, 1.0)`,
    table_stop_button_text: `rgba(${cympire_colors.red}, 0.8)`,
    table_stop_button_text_hover: `rgba(${cympire_colors.red}, 1.0)`,
    table_stop_button_border: `rgba(${cympire_colors.red}, 0.8)`,
    table_stop_button_border_hover: `rgba(${cympire_colors.red}, 1.0)`,

    table_continue_button: `rgba(${cympire_colors.black}, 1.0)`,
    table_continue_button_text: `rgba(${cympire_colors.green}, 0.8)`,
    table_continue_button_text_hover: `rgba(${cympire_colors.green}, 1.0)`,
    table_continue_button_border: `rgba(${cympire_colors.green}, 0.8)`,
    table_continue_button_border_hover: `rgba(${cympire_colors.green}, 1.0)`,

    table_terminate_button: `rgba(${cympire_colors.black}, 1.0)`,
    table_terminate_button_text: `rgba(${cympire_colors.red}, 0.8)`,
    table_terminate_button_text_hover: `rgba(${cympire_colors.red}, 1.0)`,
    table_terminate_button_border: `rgba(${cympire_colors.red}, 0.8)`,
    table_terminate_button_border_hover: `rgba(${cympire_colors.red}, 1.0)`,

    
    // MODALS
    modal_background: `rgba(${cympire_colors.black}, 0.75)`,
    modal_header_border: '#d8dbe0',
    modal_footer_border: '#d8dbe0',
    modal_success_button: `rgba(${cympire_colors.green}, 1.0)`,
    modal_success_button_text: `rgba(${cympire_colors.black}, 1.0)`,
    modal_delete_button: `rgba(${cympire_colors.red}, 1.0)`,
    modal_delete_button_text: `rgba(${cympire_colors.black}, 1.0)`,
    modal_cancel_button: `rgba(${cympire_colors.light_grey}, 1.0)`,
    modal_cancel_button_text: `rgba(${cympire_colors.black}, 1.0)`,

    // BUTTONS
    button: `rgba(${cympire_colors.black}, 1.0)`,
    button_hover: `rgba(${cympire_colors.light_grey}, 1.0)`,
    button_border: `rgba(${cympire_colors.light_grey}, 0.8)`,
    button_border_hover: `rgba(${cympire_colors.light_grey}, 1.0)`,
    button_text: `rgba(${cympire_colors.light_grey}, 0.8)`,
    button_text_hover: `rgba(${cympire_colors.light_grey}, 1.0)`,
    button_shadow: `rgba(${cympire_colors.light_grey}, 0.25)`,

    edit_button: `rgba(${cympire_colors.white}, 0.8)`,
    edit_button_hover: `rgba(${cympire_colors.white}, 1.0)`,

    add_button: `rgb(${cympire_colors.black})`,
    add_button_text: `rgb(${cympire_colors.white})`,
    add_button_border: `rgb(${cympire_colors.white})`,

    btn_light: `rgb(${cympire_colors.black})`,
    btn_light_text: `rgba(${cympire_colors.light_grey}, 0.8)`,
    btn_light_border: `rgba(${cympire_colors.light_grey}, 0.8)`,
    btn_light_hover: `rgba(${cympire_colors.light_grey}, 1.0)`,

    btn_danger: `rgb(${cympire_colors.black})`,
    btn_danger_text: `rgba(${cympire_colors.red}, 0.8)`,
    btn_danger_border: `rgba(${cympire_colors.red}, 0.8)`,
    btn_danger_hover: `rgba(${cympire_colors.red}, 1.0)`,

    btn_info: '#49bd95',
    btn_info_text: '#FFFFFF',
    btn_info_border: '#49bd95',

    btn_update: `rgba(${cympire_colors.black}, 1.0)`,
    btn_update_text: `rgba(${cympire_colors.green}, 0.8)`,
    btn_update_border: `rgba(${cympire_colors.green}, 0.8)`,
    btn_update_hover: `rgba(${cympire_colors.green}, 1.0)`,

    btn_create: `rgba(${cympire_colors.black}, 1.0)`,
    btn_create_text: `rgba(${cympire_colors.green}, 0.8)`,
    btn_create_border: `rgba(${cympire_colors.green}, 0.8)`,
    btn_create_hover: `rgba(${cympire_colors.green}, 1.0)`,

    // not in use
    add_event_button: `rgb(${cympire_colors.dark_grey})`,
    add_event_button_text: `rgba(${cympire_colors.white}, 0.6)`,
    //


    // ALERTS
    alert_danger: `rgb(${cympire_colors.black})`,
    alert_danger_text: `rgba(${cympire_colors.red}, 1.0)`,
    alert_danger_border: `rgb(${cympire_colors.red})`,

    alert_info: `rgb(${cympire_colors.black})`,
    alert_info_text: `rgba(${cympire_colors.light_grey}, 1.0)`,
    alert_info_border: `rgb(${cympire_colors.light_grey})`,

    alert_success: `rgb(${cympire_colors.black})`,
    alert_success_text: `rgba(${cympire_colors.green}, 1.0)`,
    alert_success_border: `rgb(${cympire_colors.green})`,




    /* MODULES */

    // LICENSE
    license_grid: `rgb(${cympire_colors.dark_grey})`,
    license_grid_text: `rgba(${cympire_colors.white}, 0.75)`,
    license_hline: `rgba(${cympire_colors.light_grey}, 0.75)`,
    license_info_text: `rgba(${cympire_colors.white}, 0.8)`,
    license_info_vline: `rgba(${cympire_colors.white}, 0.1)`,
    license_info_vline_text: `rgba(${cympire_colors.white}, 0.5)`,
    license_grid_vline_border: `rgba(${cympire_colors.white}, 0.1)`,
    license_campaign_grid_vline_style: 'border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(255,255,255, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) ) 1 100%;',

    // CAMPAIGNS
    campaigns_grid: `rgb(${cympire_colors.dark_grey})`,
    campaigns_grid_text: `rgba(${cympire_colors.white}, 0.75)`,
    campaigns_hline: `rgba(${cympire_colors.black}, 0.1)`,
    campaigns_info_text: `rgba(${cympire_colors.white}, 0.8)`,
    campaigns_info_vline: `rgba(${cympire_colors.white}, 0.1)`,
    campaigns_info_vline_text: `rgba(${cympire_colors.white}, 0.5)`,
    campaigns_grid_vline_border: `rgba(${cympire_colors.white}, 0.1)`,
    campaigns_section_title_text: `rgba(${cympire_colors.white}, 0.5)`,
    campaigns_topics_text: `rgba(${cympire_colors.white}, 0.9)`,
    campaigns_industries_text: `rgba(${cympire_colors.white}, 0.9)`,
    campaigns_filter_container_border: `rgba(${cympire_colors.white}, 0.3)`,
    campaigns_filter_reset_button: `rgba(${cympire_colors.white}, 0.6)`,
    campaigns_filter_reset_button_hover: `rgba(${cympire_colors.white}, 1.0)`,
    campaigns_select_campaign_button: `rgba(${cympire_colors.black}, 1.0)`,
    campaigns_select_campaign_button_text: `rgb(${cympire_colors.white})`,
    campaigns_select_campaign_button_border: `rgb(${cympire_colors.white})`,
    campaigns_button: `rgb(${cympire_colors.black})`,
    campaigns_button_text: `rgba(${cympire_colors.white}, 0.6)`,
    campaigns_button_text_hover: `rgba(${cympire_colors.white}, 1.0)`,
    campaigns_button_border: `rgb(${cympire_colors.grey})`,
    campaigns_button_border_hover: `rgb(${cympire_colors.white})`,
    campaigns_campaign_grid_vline_style: 'border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(255,255,255, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) ) 1 100%;',
    campaigns_video_brief_video_shadow: '255,255,255',


    // CAMPAIGN
    campaign_hline: `rgba(${cympire_colors.white}, 0.2)`,
    campaign_info_vline: `rgba(${cympire_colors.white}, 0.2)`,
    campaign_mission_hline: `rgba(${cympire_colors.white}, 0.2)`,
    campaign_mission_border: `rgba(${cympire_colors.white}, 0.2)`,
    campaign_section_text: `rgba(${cympire_colors.white}, 0.5)`,
    campaign_topics_text: `rgba(${cympire_colors.white}, 0.9)`,
    campaign_industries_text: `rgba(${cympire_colors.white}, 0.9)`,
    campaign_title: `rgba(${cympire_colors.white}, 0.8)`,
    campaign_subtitle: `rgba(${cympire_colors.white}, 0.7)`,
    campaign_target_image_shadow: '255, 255, 255',
    // for whiteNoise: 
    // campaign_target_image_shadow: 'box-shadow: 0 -0.5px 4px 0 rgba(0, 0, 0, 0.35), 0 1px 4px 0 rgba(102, 75, 75, 0.35);',
    
    
    
    // TEAMS
    teams_border: `rgba(${cympire_colors.white}, 0.9)`,
    teams_title: `rgba(${cympire_colors.white}, 1.0)`,
    teams_hline: `rgba(${cympire_colors.white}, 0.8)`,
    teams_container: `rgba(${cympire_colors.black}, 0.5)`,
    teams_container_border: `rgba(${cympire_colors.white}, 0.1)`,
    teams_team_title: `rgba(${cympire_colors.white}, 1.0)`,
    teams_team_subtitle: `rgba(${cympire_colors.grey}, 1.0)`,

    //  PACKAGES
    package_grid: `rgb(${cympire_colors.dark_grey})`,
    package_text: `rgba(${cympire_colors.white}, 0.75)`,
    package_hline: `rgba(${cympire_colors.white}, 0.2)`,
    package_info_text: `rgba(${cympire_colors.white}, 0.75)`,
    package_section_title: `rgba(${cympire_colors.white}, 0.5)`,
    package_topics: `rgba(${cympire_colors.white}, 0.8)`,
    package_industries: `rgba(${cympire_colors.white}, 0.8)`,
    package_grid_vline_style: 'border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(255,255,255, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) ) 1 100%;',

    // TEAMS
    teams_avatar_image_border: `rgb(${cympire_colors.grey})`,
    teams_player_text: `rgba(${cympire_colors.white}, 0.75)`,
    team_disabled_notification_text: `rgba(${cympire_colors.red}, 0.75)`,

    // USERS
    users_avatar_image_border: `rgb(${cympire_colors.grey})`,

    // NETWROK MAP (CAMPAIGN BUILDER)
    network_map_text: `rgba(${cympire_colors.white}, 0.75)`,
    network_map_background: `rgba(${cympire_colors.black}, 1.0)`,
    network_map_border: `rgba(${cympire_colors.light_grey}, 0.5)`,
    network_map_input: `rgba(${cympire_colors.black}, 1.0)`,
    network_map_segment_border: `rgba(${cympire_colors.light_grey}, 0.25)`,
    network_map_object: `rgba(${cympire_colors.white}, 0.1)`,
    network_map_vline_style: 'border-image: linear-gradient( to bottom, rgba(0,0,0, 0.1) , rgba(255,255,255, 0.15), rgba(255,255,255, 0.2), rgba(255,255,255, 0.15), rgba(0,0,0, 0.1) ) 1 100%;',
    startup_script_text_color: `rgba(${cympire_colors.cybergreen}, 1.0)`,
    startup_script_border_color: `rgba(${cympire_colors.cyberpink}, 1.0)`,

    // EVENT DASHBOARD (COPY TO WHITENOISE)
    event_dashboard_completed_text: `rgba(${cympire_colors.grey}, 1.0)`,
    event_dashboard_card_border: `rgba(${cympire_colors.white}, 0.25)`,
    event_dashboard_divider: `rgba(${cympire_colors.light_grey}, 0.5)`,
    event_dashboard_card_divider: `rgba(${cympire_colors.light_grey}, 0.5)`,
    event_dashboard_white_border: `rgba(${cympire_colors.white}, 1.0)`,
    event_dashboard_grey_border: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    event_dashboard_red_border: `rgba(${cympire_colors.red}, 1.0)`,
    event_dashboard_blue_border: `rgba(${cympire_colors.blue}, 1.0)`,
    event_dashboard_green_border: `rgba(${cympire_colors.green}, 1.0)`,
    event_dashboard_white_text: `rgba(${cympire_colors.white}, 1.0)`,
    event_dashboard_grey_background: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    event_dashboard_black_background: `rgba(${cympire_colors.black}, 1.0)`,


    // ATTACK SCENARIO
    attack_scenario_timeline: "transparent",
    attack_scenario_timeline_border: `rgba(${cympire_colors.red}, 0.5)`,
    attack_scenario_background: `rgba(${cympire_colors.black}, 1.0)`,
    attack_scenario_border: `rgba(${cympire_colors.light_grey}, 0.5)`,
    attack_scenario_target_phases_text: `rgba(${cympire_colors.white}, 0.5)`,
    attack_scenario_target_phases_border: `rgba(${cympire_colors.light_grey}, 0.25)`,
    attack_scenario_side_border: `rgba(${cympire_colors.red}, 1.0)`,


    // NEW DASHBOARD
    red_color: `rgba(${cympire_colors.red}, 1)`,
    blue_color: `rgba(${cympire_colors.blue_2}, 1)`,
    green_color: `rgba(${cympire_colors.green_2}, 1)`,
    card_bg: `rgba(${cympire_colors.gray_4}, 1)`,
    

    // READINESS DASHBOARD
    players_dashboard_score_background: `rgba(${cympire_colors.navy}, 0.4)`,
    players_dashboard_username_background: `rgba(${cympire_colors.navy}, 0.4)`,
    players_dashboard_topic_black: `rgba(${cympire_colors.black}, 1.0)`,
    players_dashboard_topic_green: `rgba(${cympire_colors.green}, 1.0)`,
    players_dashboard_topic_yellow: `rgba(${cympire_colors.yellow}, 0.75)`,
    players_dashboard_topic_orange: `rgba(${cympire_colors.orange}, 0.75)`,
    players_dashboard_topic_red: `rgba(${cympire_colors.red}, 1.0)`,

    players_dashboard_readiness_background: `rgba(${cympire_colors.white}, 0.02)`,
    players_dashboard_readiness_shadow: 'box-shadow: inset 0 6px 6px rgba(255,255,255, 0.1), 0 6px 6px rgba(255,255,255, 0.1);',
    players_dashboard_score_badge_header: `rgba(${cympire_colors.navy}, 1.0)`,
    players_dashboard_score_badge_body: `rgba(${cympire_colors.white}, 0.01)`,
    players_dashboard_score_badge_footer: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    players_dashboard_score_badge_text: `rgba(${cympire_colors.white}, 0.65)`,
    players_dashboard_score_badge_shadow: 'box-shadow: inset 0 0 10px rgba(255,255,255, 0.25);',
    score_change_red: `rgba(${cympire_colors.red}, 1.0)`,
    score_change_green: `rgba(${cympire_colors.green}, 1.0)`,


    // RANK BADGES
    rank_badge_header: `rgba(${cympire_colors.navy}, 1.0)`,
    rank_badge_body: `rgba(${cympire_colors.black}, 0.5)`,
    rank_badge_footer: `rgba(${cympire_colors.dark_grey}, 1.0)`,
    rank_badge_text: `rgba(${cympire_colors.white}, 0.65)`,
    rank_badge_shadow: 'box-shadow: inset 0 0 10px rgba(255,255,255, 0.25);',



    // LEADERBOARD
    leaderboard_team_grid: `background-image: linear-gradient(75deg, rgba(${cympire_colors.white}, 0.02) 10%, rgba(${cympire_colors.purple}, 0.5) 79%, rgba(${cympire_colors.black}, 1.0) 79%, rgba(${cympire_colors.black}, 1.0) 80%, rgba(${cympire_colors.purple}, 0.5) 80% , rgba(${cympire_colors.old_green}, 0.5) 100%);`,
    leaderboard_team_grid_content_name: `rgba(${cympire_colors.white}, 0.65)`,

}







export const player = {
    body: `rgb(${cympire_colors.black})`,
    // GLOBAL
    background: `rgb(${cympire_colors.black})`,
    base_background: `rgb(${cympire_colors.black})`,
    link: `rgb(${cympire_colors.grey})`,
    link_hover: `rgb(${cympire_colors.white})`
}