import { 
    TENANT_INFO,
    TENANT_NAME,
    SETTINGS,
    SYSTEM_INFO,
    LOGIN_DETAILS,
    SOUND,
    AUTO_REFRESH,
    API_BASE_URL,
    HISTORY_COUNTER,
    USER_DATA,
    ACTIVE_EVENTS } from "../actions/actionTypes";

const initialState = {
    tenant_info: {},
    settings: {},
    system: {},
    login: {},
    sound: {mute: false},
    auto_refresh: { refresh: true },
    tenant: {},
    api: {},
    history_counter: {},
    user_data: {},
    active_events: {}
}

const appDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case TENANT_INFO: {
            return {...state, tenant_info: action.payload};
        }
        case SETTINGS: {
            return {...state, settings: action.payload};
        }
        case SYSTEM_INFO: {
            return {...state, system: action.payload};
        }
        case LOGIN_DETAILS: {
            return {...state, login: action.payload};
        }
        case SOUND: {
            return {...state, sound: action.payload};
        }
        case AUTO_REFRESH: {
            return {...state, auto_refresh: action.payload};
        }
        case TENANT_NAME: {
            return {...state, tenant: action.payload};
        }
        case API_BASE_URL: {
            return {...state, api: action.payload};
        }
        case HISTORY_COUNTER: {
            return {...state, history_counter: action.payload};
        }
        case USER_DATA: {
            return {...state, user_data: action.payload};
        }
        case ACTIVE_EVENTS: {
            return {...state, active_events: action.payload};
        }
        default:
            return state;
    }
};

export default appDataReducer;


