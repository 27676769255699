import { combineReducers } from 'redux';
import userActivityReducer from './userActivityReducer';
import showSideBarReducer from './showSideBarReducer';
import campaignBuilderReducer from './campaignBuilderReducer';
import appDataReducer from './appDataReducer';


const rootReducer = combineReducers({
  userActivityReducer: userActivityReducer,
  showSideBarReducer: showSideBarReducer,
  campaignBuilderReducer: campaignBuilderReducer,
  appDataReducer: appDataReducer
});

export default rootReducer;