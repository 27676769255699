import { USER_ACTIVITY } from "../actions/actionTypes";

const initState = {
    events: 0,
    players: 0
}

const userActivityReducer = (state = initState, action) => {
    switch (action.type) {
        case USER_ACTIVITY: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default userActivityReducer;
